import React ,{useContext,useEffect} from 'react';
import { EnvironmentContext } from '../index';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from '../img/new-signulu-logo.png';
import smallLogo from '../img/logo.png';
import downArrow from '../img/down-arrow.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';
 
function Header() {
  const  env = useContext(EnvironmentContext);
  useEffect(() => {
    AOS.init({
      once: true,
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $('body').addClass('sticky');
      } else {
        $('body').removeClass('sticky');
      }
    });

    $('.nav-link').click(function () {
      $('.nav_dropDown').slideToggle(600);
    });

    $('.counter').each(function () {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 4000,
        easing: 'swing',
        step: function (now) {
          $(this).text(Math.ceil(now));
        }
      });
    });

    (function () {
      var words = ['Efficient', 'Secure', 'Trustworthy'], i = 0;
      setInterval(function () {
        $('#changingword').fadeOut(function () {
          $(this).html(words[i = (i + 1) % words.length]).fadeIn();
        });
      }, 3000);
    })();

    function dataWord() {
      $("[data-words]").attr("data-words", function (i, d) {
        var $self = $(this),
          $words = d.split("|"),
          tot = $words.length,
          c = 0;

        for (var i = 0; i < tot; i++) $self.append($('<span/>', { text: $words[i] }));
        $words = $self.find("span").hide();

        (function loop() {
          $self.animate({ width: $words.eq(c).width() });
          $words.stop().fadeOut().eq(c).fadeIn().delay(2000).show(0, loop);
          c = ++c % tot;
        }());
      });
    }

    $(window).on('load', dataWord);
  }, []);
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container container-xxl container-xl container-lg">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="top-bar-logo" alt="Logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <Link className="navbar-brand" to="/">
                <img src={smallLogo} alt="Small Logo" />
              </Link>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body" id="nav-menu">
              <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/about">About</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Industry Solutions <img src={downArrow} alt="Down Arrow" />
                  </a>
                  <div className="nav_dropDown" style={{ zIndex: 1000 }}>
                    <ul>
                      <li><Link to="/manufacturing">Manufacturing</Link></li>
                      <li><Link to="/banking-financial-services">Banking and Financial Services</Link></li>
                      <li><Link to="/professional-services">Professional Services</Link></li>
                      <li><Link to="/real-estate">Real Estate</Link></li>
                      <li><Link to="/healthcare">Healthcare</Link></li>
                      <li><Link to="/law">Law</Link></li>
                      <li><Link to="/it">IT</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/pricing">Pricing</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="https://blog.signulu.com/">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
              </ul>
              <div className="d-flex start-trl align-items-center">
                <a href={`${env}`}>Login</a>
                <a href={`${env}/account/register`}>Start Trial</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
