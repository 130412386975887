import React,{useContext} from "react";
import { EnvironmentContext } from '../index';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import tick from "../img/tick.png";
import proseal from "../img/pro-ser-1.png";
import proser2 from "../img/pro-ser-2.png"
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import icon4 from "../img/icon4.png";
import icon5 from "../img/bank-fin-icon2.png";
import frame from "../img/Frame.png"

function Professional() {
  const  env = useContext(EnvironmentContext);
  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt top_art bank_fin w-100" data-aos="fade-up" data-aos-duration="2500">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-sm-4">
            <img src={proseal} alt="" />
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 text-part">
            <div className="pb-4">
              <h3>Utilizing e-Signature in the <span className="efcnt blue-grdnt-text">Staffing</span> Industry</h3>
            </div>
            <button className="btn-rd" type="button" onClick={() => window.location.href = `${env}/account/register`}>
              Start Free Trial <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </button>
          </div>
        </div>
      </section>

      <section className="top-prt pt-2">
        <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <div>
            <p>
              In today's digital world, applying transformational strategies has become the single most important priority for virtually every business and the recruiting and staffing industry is no exception. The process of identifying, screening and interviewing candidates has become ever more challenging as the result of the COVID-19 virus. And, when you cannot meet these interviewees in person, it has also become more difficult to have them sign all the necessary forms they typically do upon a visit to your office. One of the tools most commonly being implemented to overcome these conditions is an e-Signature solution like Signulu. Why?
            </p>
            <p>
              Everyday recruiters have to deal with dozens of candidates who are often asked to review and sign any number of documents from non-disclosure agreements, background checks and possibly immigration forms. Oh, and they need to do so ASAP! The process can be a lengthy and exhaustive one nowadays. However, thanks to e-Signature solutions, businesses can now manage these tasks more securely, timely and efficiently.
            </p>
          </div>
          <div className="bank_fin_btn_rd">
            <a href={`${env}/account/register`} className="btn-rd">
              Start Free Trial <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center pb-4">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>Benefits to Recruiting Professionals in using <span><span className="health_small">e</span>Signature </span>Solutions from Signulu</h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={proser2} alt="" />
            </div>
          </div>
          <div className="real_est_sub_container3_2">
            <div className="manfact_sub_container3_2">
              <div className="manfact_sub_container3_2_p">
                <div className="manfact_sub_container3_2_1" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_2_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i><span style={{ marginLeft: '6px' }}>New Candidate Documentation</span><br />
                      You do not want to let the right talent slip away due to lengthy and annoying recruitment processes. With an e-signature platform like Signulu, a staffing professional can expedite the entire document management process and get necessary forms signed up to 80% faster than the old-fashioned paper-based routines. And, with a majority of job seekers being Millennials and Gen Z'ers, it is important for your company to adjust its routines to better match the way this demographic is accustomed to an exclusively digital lifestyle.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i><span style={{ marginLeft: '6px' }}>Faster approvals of Candidates’ Non-Disclosure Agreement</span><br />
                      Get candidates to sign their NDA’s well in advance with eSignature solutions, thus expediting recruiting workflows and keeping prospective employers happy.
                    </li>
                  </ul>
                </div>
                <div className="manfact_sub_container3_3" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_3_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i><span style={{ marginLeft: '6px' }}>Easily Track Document Status</span><br />
                      Cut down on unnecessary paperwork and the inconveniences of having to follow up via phone or email to check on the status of pending documents, be it employees, contractors or candidates. Signulu's simple eSignature solution will make it possible to get your documents signed in minutes or hours versus days or even weeks. Further, with the user dashboard, you can oversee document status in real-time.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i><span style={{ marginLeft: '6px' }}>For efficient PTO management</span><br />
                      Signulu's integrated eSignature solutions will enable employees to fill out, sign and upload their timesheets and PTO forms into the Payroll or HR systems using open API’s with more efficiency.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="manfact_btn_knowmore">
                <button className="btn-rd" type="button" onClick={() => window.location.pathname = '/contact'}>
                  Know More <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            Summary of Signulu Benefits:
          </h2>
          <div className="row add_padding justify-content-center align-items-center" data-aos="fade-up" data-aos-duration="1500">
            <div className="col-xl-3 col-md-6 padding">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon1} alt="" />
                </div>
                <div className="right">
                  <p>Speed up recruitment processes</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon2} alt="" />
                </div>
                <div className="right">
                  <p>Get all vital documents signed on the go</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon3} alt="" />
                </div>
                <div className="right">
                  <p>Avoid the discrepancies of traditional paperwork</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon4} alt="" />
                </div>
                <div className="right">
                  <p>Achieve overhead savings on costs of paper, toner, hardware and postage.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon5} alt="" />
                </div>
                <div className="right">
                  <p>Track document status with the administrator dashboard.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <p>
            Signulu allows recruiting professionals to focus more of their time on revenue generating activities like candidate identification and requirements pipeline development. This is achieved by streamlining administrative functions inherent with interviewing, hiring and onboarding. Our eSignature platform will save time, increase compliance, reduce errors, and improve the overall document management experience.
          </p>
          <p>
            Sign up for Signulu's Free 14 Day Trial Today!
          </p>
          <a href={`${env}/account/register`} className="btn-rd">
            Start Free Trial <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
            </svg>
          </a>
        </div>

      </section>

      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  checked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Professional;