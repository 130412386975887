import React from "react";
import infoimg from "../img/info-i-20.png";
import tickimg from "../img/tick-black-24.png";
import wrongimg from "../img/wrong-24-b.png";

function PricingTable() {
  const pricingData = {
    headers: ['Signulu Lite', 'Individual', 'SMB Plan', 'Enterprise'],
    rows: [
      [
        { content: 'Signing Workflow', tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'No. of Recipients per Envelope', tooltip: null },
        { content: '2', tooltip: null },
        { content: '5', tooltip: null },
        { content: '10', tooltip: null },
        { content: 'Custom', tooltip: null },
      ],
      [
        { content: 'Max size of the envelope', tooltip: null },
        { content: '5 MB', tooltip: null },
        { content: '30 MB', tooltip: null },
        { content: '150 MB', tooltip: null },
        { content: 'Custom', tooltip: null },
      ],
      [
        { content: 'Basic Fields', tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Advanced Fields', tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Real Time Audit Trail', tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Multiple Languages', tooltip: 'View the application in your choice of the Language. Currently we provide 5 language options, default being English. More languages coming soon.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Notifications and Reminders', tooltip: 'Set up automatic reminders and document validity dates to ensure signatories sign the documents on time. No more manual follow-ups, allow Signulu to do that for you.' },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Mobile App', tooltip: 'Sign Documents on- the-go using Signulu Mobile app and access documents anytime, anywhere.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Mobile responsive', tooltip: 'Signers on mobile devices can sign the documents using an alternative version of the document that automatically adapts its formatting to the device’s size and orientation within the browser.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Reports', tooltip: 'Access reports to get visibility on document signing activities and also you export the reports to Microsoft Excel to review performance.' },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Templates', tooltip: 'Create templates from already signed documents/agreements to reuse them at a later point in time. This saves time, reduces risk of errors and standardize processes.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Commenting', tooltip: 'Recipients can comment on the document sent for signature and send it back to the initiator for modifications/changes.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Scheduled Sending', tooltip: 'Prepare the documents/contracts and set up a timer (future date) to send the document for signature.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'In-Person Signing', tooltip: 'In-person signing eliminates wet ink signatures and allows eSignatures' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Custom Branding', tooltip: 'Embed your company’s logo on eSignatures and other artifacts to give a personalized experience.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'User Permissions', tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Team Reports', tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Shared Templates', tooltip: 'Share reusable templates across the organization for everybody else’s use.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Shared Documents and Folders', tooltip: 'Organize your documents by creating folders for easy access and retrievability.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Delegated Signing', tooltip: 'Not in Office or on Vacation? Don’t worry, just delegate document signing to your co-worker while you are away.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Bulk Send', tooltip: 'Want to send single/unique document to multiple people, just import the list of recipients send it in one click.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Payments', tooltip: 'Want to receive payment from your client along with the eSignature, just add the payment filed in the document and set the amount to be received.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Integrations', tooltip: 'Signulu can be integrated with any third party enterprise applications through REST APIs so that the user gets a seamless experience and doesn’t have to straddle with multiple applications.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Cloud Storage', tooltip: 'Store executed documents on any third-party cloud storage service providers to access them anywhere and anytime.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Local Storage', tooltip: 'Signulu provides storage capacity to store documents within the application. The size of the storage is defined based on the subscription plan.' },
        { content: 'Up to 1 GB', tooltip: null },
        { content: 'Up to 2 GB', tooltip: null },
        { content: 'Up to 5 GB per user', tooltip: null },
        { content: 'Custom Plan', tooltip: null },
      ],
      [
        { content: 'Custom Storage', tooltip: 'Want to store documents separately in any storage servers (SFTPs), Signulu provides connectors to push the document to remote storage servers.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Multi-Factor Authentication', tooltip: 'Secure your document signing process by setting up 2FA.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Notary', tooltip: 'Notarize your documents remotely using Notary feature in Signulu.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Send for Review', tooltip: 'Recipients can comment on the document sent for signature and send it back to the initiator for modifications/changes.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Document Authoring', tooltip: 'You can author your document within Signulu by using our Document Authoring module and send it for signature.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
      [
        { content: 'Biometrics', tooltip: 'Validate signer identity before signing the document by using biometrics tools. Prevent any fraudulent activities in the contract execution process.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: 'Custom Plan', tooltip: null },
      ],
      [
        { content: 'SSO', tooltip: 'Manage access to users using SSO feature.' },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={wrongimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
        { content: <img src={tickimg} alt="" />, tooltip: null },
      ],
    ],
  };
  return (
    <div>
      <section className="table-container">
        <table>
          <thead>
            <tr>
              <th></th>
              {pricingData.headers.map((header, index) => (
                <th style={{ textAlign: "center" }} key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pricingData.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td style={{ border: "1px solid #ddd", padding: "8px" }} key={cellIndex} className={cell.tooltip ? 'for-tooltips' : ''}>
                    {cell.tooltip ? (
                      <>
                        {cell.content}
                        <span className="tooltip">
                          <img src={infoimg} alt="" />
                          <span className="tooltiptext">{cell.tooltip}</span>
                        </span>
                      </>
                    ) : (
                      cell.content
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  )
}
export default PricingTable;
