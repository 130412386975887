import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './Css/Custom.css';
import Home from "./Components/Home";
import TermsofServices from "./Components/Terms of Services";
import PrivacyPolicy from "./Components/Privacy Policy";
import AllPages from "./MainPages/AllPages";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Terms of Services" element={<TermsofServices />} />
          <Route path="/Privacy Policy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<AllPages />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
