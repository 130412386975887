import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';

import Header from '../Components/Header';
import Footer from '../Components/Footer';

import tick from "../img/tick.png";
import img1 from "../img/Bank-Fin-img1.png";
import img2 from "../img/bank-fin-img2.png";
import frame from "../img/Frame.png";
import icon1 from "../img/bank-fin-icon1.png";
import icon2 from "../img/bank-fin-icon2.png"
import icon3 from "../img/bank-fin-icon3.png"
import icon4 from "../img/bank-fin-icon4.png"
import icon5 from "../img/bank-fin-icon5.png"
import icon6 from "../img/bank-fin-icon6.png"

function BankingandFinancial() {
  
  const  env = useContext(EnvironmentContext);
  
  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt top_art bank_fin" data-aos="fade-up" data-aos-duration="2500">
        <div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-sm-4">
              <img src={img1} alt="" />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 text-part">
              <h3>
                LEVERAGING <span className="efcnt blue-grdnt-text">eSIGNATURE</span>
                <br />
                FOR THE BANKING <br />
                & FINANCE INDUSTRY
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="top-prt pt-2">
        <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <div>
            <div>
              <p>
                Did you know that over half of all of business transactions are expected to be made from mobile devices by the end of 2020? In a world that is transitioning to a paperless, remote workplace, eSignature solutions have become more important than ever. The finance and banking industries deal with an overwhelming amount of documentation every single day. Not to mention, the ever-changing regulatory backdrop and customer expectations in which you operate demand your institution is more secure, agile, and accessible than ever before.
              </p>
            </div>
            <div>
              <p>
                Whether you are a boutique finance firm, a mortgage lender, assets management firm or in retail banking - Signulu is all about boosting your document management workflow, efficiency, and alleviating many of your manual tasks with our cloud-based eSignature solution.
              </p>
            </div>
          </div>
          <div className="bank_fin_btn_rd">
            <a  href={`${env}/account/register`} className="btn-rd">
              Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="top-prt health_sec health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center pb-4">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>
                how will signulu benefit your <br />
                <span>financial institution? </span>
              </h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={img2} alt="" />
            </div>
          </div>
          <div className="d-flex justify-content-start mb-2">
            <div className="bank_fin_ul-1" data-aos="fade-up" data-aos-duration="2500">
              <ul type="none">
                <li>
                  <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                  <span style={{ marginLeft: '6px' }}>Documents in your clients’ hands faster than ever before</span>
                  <br />
                  Avoid delays and reduce the time it takes to get documents authorized by over 80%! Because the whole distribution and authorization process occurs online, there is no need to copy, scan or mail documents then wait days for your clients to sign and send them back. Rather, you can send and receive your documents back in minutes, rather than hours or days, because Signulu can be accessed from a phone or pc. If you have a data connection, then business will get done anywhere anytime.
                </li>
                <li>
                  <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                  <span style={{ marginLeft: '6px' }}>Clear visibility to document status</span>
                  <br />
                  No more wondering if your customer has received, much less approved documents you have sent them. Using Signulu’s transaction dashboard, get clear real-time visibility into who has received and signed all necessary forms. Especially helpful when multiple parties need to review the same set of documents. Know immediately which party is delaying the process.
                </li>
              </ul>
            </div>
            <div className="bank_fin_ul-2" data-aos="fade-up" data-aos-duration="2500">
              <ul type="none">
                <li>
                  <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                  <span style={{ marginLeft: '6px' }}>Stay secure, trusted and compliant</span>
                  <br />
                  Signulu uses AWS TLS 1.2 encryption that offers privacy and protection of all user's data. Signulu guarantees other several security features - such as tamper-proof audit trail, data encryption, and signer authentication. It is compliant with industry standards for protection, continuity, availability, and system confidentiality. Further, Signulu is legally binding as it complies with all major eSignature laws including ESIGN, ITA-2000.
                </li>
                <li>
                  <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                  <span style={{ marginLeft: '6px' }}>Drastically Reduce Overhead Costs & Create a “Greener” Workplace</span>
                  <br />
                  How many reams of paper and toner cartridges does your organization go through in a month? In a year? It is probably much more than you would like, both from a cost perspective and from an environmental one. According to the Sierra Club, the United States produces over 20 million TONS of paperwork each year. Yet, only a bit more than half of that waste is ever recycled. By utilizing a completely digital platform for document management, you can significantly reduce overhead costs and save a few trees while you are at it, as well.
                </li>
              </ul>
            </div>
          </div>
          <div className="bank_fin_main_list_items">
            <div className="bank_fin_main_list_items_h3">
              <h3>
                <b>Various use cases of Signulu's eSignature for Finance Companies</b>
              </h3>
            </div>
            <div className="bank_fin_main_list_items_ul">
              <div className="bank_fin_main_list_items_ul">
                <ul>
                  <li>Transfer of assets</li>
                  <li>Loan and mortgage applications</li>
                  <li>Change and approval of beneficiary forms</li>
                  <li>Change and approval of address forms</li>
                  <li>Retainer contracts</li>
                </ul>
              </div>
              <div className="bank_fin_main_list_items_ul">
                <ul>
                  <li>Maintenance and account change forms</li>
                  <li>Delegation of authority</li>
                  <li>Preparation and approval of credit reports</li>
                  <li>Audit sign off</li>
                  <li>Disclosures</li>
                </ul>
              </div>
              <div className="bank_fin_main_list_items_ul">
                <ul>
                  <li>New account openings</li>
                  <li>Subscription documentation</li>
                  <li>Redemption requests</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bank_fin_btn_knowmore">
            <a href="/contact" className="btn-rd">
              Know More
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            Summary of Signulu Benefits:
          </h2>
          <div className="row add_padding justify-content-center align-items-center">
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon1} alt="" />
                </div>
                <div className="right">
                  <p>Speed up In-person eSigning</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon2} alt="" />
                </div>
                <div className="right">
                  <p>Receive Timely Payments</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon3} alt="" />
                </div>
                <div className="right">
                  <p>Manage Complex eSignature Workflows</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon4} alt="" />
                </div>
                <div className="right">
                  <p>Avoid Retyping the Same Information Over and Over</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon5} alt="" />
                </div>
                <div className="right">
                  <p>Seamlessly Collaborate Inside Your Department</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon6} alt="" />
                </div>
                <div className="right">
                  <p>Store Documents in Secure Cloud Storage</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <p>
            Using an eSignature solution like Signulu will create more efficient workflows for your internal and external document management processes, alike. Do not let mountains of paperwork get in the way of your company’s productivity. There is a better way to do business and you are only a few clicks away from beginning that journey.
          </p>
          <p>
            If you feel that Signulu can help your banking or financial organization achieve its digital transformation goals, give us a <a href="contact.html">call today </a> and start enjoying the advantages of our platform immediately. And best of all you can try the full version FREE with our 14-day free trial period.
          </p>
          <a href="/contact" className="btn-rd">
            Know More
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
            </svg>
          </a>
        </div>

      </section>

      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trail</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked={true}
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default BankingandFinancial;