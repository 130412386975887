import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import address from "../img/contact-1.png"
import email from "../img/contact-2.png"
import phone from "../img/contact-3.png"
import Frame from "../img/Frame.png"

function Contacts() {
  const  env = useContext(EnvironmentContext);

  //   AOS.init({
  //     once: true,
  //     disable: () => window.innerWidth < 800,
  //   });

  //   $(window).scroll(function () {
  //     if ($(this).scrollTop() > 50) {
  //       $('body').addClass("sticky");
  //     } else {
  //       $('body').removeClass("sticky");
  //     }
  //   });

  //   $(document).ready(function () {
  //     $(".nav-link").click(function () {
  //       $(".nav_dropDown").slideToggle(600);
  //     });

  //     $('.counter').each(function () {
  //       $(this).prop('Counter', 0).animate({
  //         Counter: $(this).text()
  //       }, {
  //         duration: 4000,
  //         easing: 'swing',
  //         step: function (now) {
  //           $(this).text(Math.ceil(now));
  //         }
  //       });
  //     });

  //     (function () {
  //       var words = [
  //         'Efficient',
  //         ' Secure',
  //         ' Trustworthy'
  //       ], i = 0;
  //       setInterval(function () {
  //         $('#changingword').fadeOut(function () {
  //           $(this).html(words[i = (i + 1) % words.length]).fadeIn();
  //         });
  //       }, 3000);
  //     })();

  //     $(window).on("load", dataWord);
  //     function dataWord() {
  //       $("[data-words]").attr("data-words", function (i, d) {
  //         var $self = $(this),
  //           $words = d.split("|"),
  //           tot = $words.length,
  //           c = 0;

  //         for (var i = 0; i < tot; i++) $self.append($('<span/>', { text: $words[i] }));
  //         $words = $self.find("span").hide();

  //         (function loop() {
  //           $self.animate({ width: $words.eq(c).width() });
  //           $words.stop().fadeOut().eq(c).fadeIn().delay(2000).show(0, loop);
  //           c = ++c % tot;
  //         }());
  //       });
  //     }
  //   });
  // }, []);
  return (
    <div>
      <Header />
      <section className="pricing_box">
        <div className="container container-lg container-xl container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="heading" data-aos="fade-up" data-aos-duration="1500">
                <h2>Signulu <span>Contact</span></h2>
                <p>You can contact us using any of the below modes, and we will be happy to assist you or answer your queries.</p>
              </div>
            </div>
          </div>
          <div className="row contact_detais" data-aos="fade-up" data-aos-duration="1500">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="contact_div d-flex">
                <div className="images">
                  <img src={address} alt="Contact 1" />
                </div>
                <div className="text">
                  <h4>Address:</h4>
                  <p>101 Morgan Lane, Suite #188 Plainsboro, NJ 08536-3315, USA.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 borders">
              <div className="contact_div d-flex">
                <div className="images">
                  <img src={email} alt="Contact 2" />
                </div>
                <div className="text">
                  <h4>Email Us:</h4>
                  <a href="mailto:info@signulu.com">info@signulu.com,</a><br />
                  <a href="mailto:support@signulu.com">support@signulu.com</a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 padding_left">
              <div className="contact_div d-flex">
                <div className="images">
                  <img src={phone} alt="Contact 3" />
                </div>
                <div className="text">
                  <h4>Phone Number:</h4>
                  <a href="tel:+1-6098530237">+1-6098530237</a><br />
                  <a href="tel:+91 9176 446858">+91 9176 446858</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row contact_input">
            <div className="headline" data-aos="fade-up" data-aos-duration="1500">
              <h2>Have a <span>Question?</span></h2>
              <p>Please enter your information below <span>and a Signulu associate will</span> contact you.</p>
            </div>
            <div className="row input_div" data-aos="fade-up" data-aos-duration="1500">
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <input type="text" placeholder="Name of Organization" />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <input type="text" placeholder="First Name" />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <input type="text" placeholder="Last Name" />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <input type="text" placeholder="Email Address" />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <input type="text" placeholder="Phone" />
              </div>
              <div className="col-xl-4 col-md-6 col-sm-12 padd_battom">
                <select name="" id="">
                  <option value="">Sales Enquiry</option>
                  <option value="">Support</option>
                  <option value="">Partnerships</option>
                  <option value="">Others</option>
                </select>
              </div>
              <div className="col-12">
                <textarea placeholder="Enter your message"></textarea>
              </div>
              <div className="col-12 btn_padd">
                <button type="submit" onClick={() => window.location.href = `${env}`}>Send message</button>
              </div>
              <div className="col-12 paragraphy">
                <p>Or feel free to contact us at our Helpline: <span>+1-6098530237 &amp; +91 9176 446858</span> for more information.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_map" data-aos="fade-up" data-aos-duration="1500">
        <div className="container-fluid">
          <div className="row">
            <div className="contact_map_div">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3041.5176009771158!2d-74.58543110000001!3d40.3308625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3ddfa4674964d%3A0x8ea3dfe4a888216!2s101%20Morgan%20Ln%2C%20Plainsboro%20Township%2C%20NJ%2008536%2C%20USA!5e0!3m2!1sen!2sin!4v1687524138103!5m2!1sen!2sin"
                width="800"
                height="600"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="trial-box mar-contact" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="rememberme"
                  type="checkbox"
                  defaultChecked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={Frame} className="frame-img" alt="Frame" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contacts;
