import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import tick from "../img/tick.png";
import frame from "../img/Frame.png";
import lawbanner1 from "../img/law_banner_img1.png";
import lawimg1 from "../img/law_solution_img2.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import icon8 from "../img/rel-icon8.png";
import icon9 from "../img/rel-icon9.png";
import bankfin from "../img/bank-fin-icon2.png";
function Law() {
  const  env = useContext(EnvironmentContext);

  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={lawbanner1} alt="Law Banner" />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>
                Making THE <span>BUSINESS</span> OF LAW EASIER
              </h3>
              <button
                className="btn-rd"
                type="button"
                onClick={() => window.location.href =  `${env}/account/register`}
              >
                Start Free Trial
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="top-prt pt-2">
        <div className="row health_adding p" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <div>
            <div>
              <p> Decreasing, if not completely eliminating, the reliance on paper to get business done is a focus of many organizations right now. None more so than the legal industry where boxes and file cabinets full of documents are still a common sight in many offices. So, too, are stacks of expensive overnight envelopes, toner cartridges and reams of paper. That is why so many law firms are turning to eSignature solutions to streamline their document management and authorization processes. By using a platform like Signulu law firms can significantly decrease administration and overhead expenses, present a contemporary image to clients and have vital agreements, contracts and forms signed in minutes versus hours or days.</p>
            </div>
            <div>
              <p>No longer will staff have to endure the lengthy and seemingly pointless process of printing documents, having them signed by hand, scanned, sent to the client, wait to have them returned only to then make more copies, scans and files. Wow! That is even tough enough to read much less have to perform. This results in remarkable process inefficiencies and errors, as well as, avoidable costs.</p>
            </div>
            <div>
              <p>Further, you can rest assured knowing that these documents will be signed with full encryption and stored securely on our AWS cloud servers.</p>
            </div>
          </div>
          <div className="bank_fin_btn_rd">
            <a href={`${env}/account/register`} className="btn-rd">Start Free  Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>A quick look at how our <span><span className="health_small">e</span>Signature </span>software will benefit your law firm</h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={lawimg1} alt="" />
            </div>
          </div>
          <div className="real_est_sub_container3_2">
            <div className="manfact_sub_container3_2">
              <div className="manfact_sub_container3_2_p">
                <div className="manfact_sub_container3_2_1" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_2_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Security</span><br />
                      Signulu provides for full encryption of signatures and data to protect the users confidentiality, identity and privacy.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Legally binding</span><br />
                      Signulu adheres to all the guidelines of the eSign Act and UETA. eSignatures are able to be audited using each signature’s unique digital certificate.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Cost Efficient</span><br />
                      Greatly reduce the use and expense of paper, printer toner and expedited mail costs.
                    </li>
                  </ul>
                </div>
                <div className="manfact_sub_container3_3" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_3_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Reduce errors</span><br />
                      Because documents can be saved as templates and automatically stored after approvals, there is less room for potential clerical errors.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Time Savings</span><br />
                      Significantly shortens your document creation, distribution and approval cycle as clients can sign from their phone or computer in just seconds.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="manfact_btn_knowmore">
                <button className="btn-rd" type="button" onClick={() => window.location.pathname = '/contact'}>
                  Know More
                  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            How Signulu Streamlines Document Workflow for Law Firms
          </h2>
          <div className="row add_padding justify-content-center align-items-center">
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon2} alt="" />
                </div>
                <div className="right">
                  <p>Invite Clients to Sign a Legal Form in Seconds</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon3} alt="" />
                </div>
                <div className="right">
                  <p>Get Documents eSigned even On-the-go</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon8} alt="" />
                </div>
                <div className="right">
                  <p>Keep Confidential Data Protected</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon9} alt="" />
                </div>
                <div className="right">
                  <p>Seamlessly Collaborate on Documents</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={bankfin} alt="" />
                </div>
                <div className="right">
                  <p>Make your Documents Look Professional</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
            <p>By spending less time on document administration, office management and paralegals can devote themselves to more significant client development and retention efforts rather than in front of a copier or completing overnight mail forms. Employees will be happy, clients will be happier and your bottom line will look better for using Signulu, the digital signature platform made for your business.</p>
            <p>If you feel your firm would benefit from our eSignature and document management solution,
              <a href="/contact">click here</a> to give us a call or send us an email for a demo or <a href="https://dev.signulu.com/account/register">register here</a> for a free 14 day trial!</p>
            <a href={`${env}/account/register`} className="btn-rd">Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default Law;