import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import tick from "../img/tick.png";
import frame from "../img/Frame.png";
import itimg from "../img/it_banner_img.png";
import itimg1 from "../img/it_solution_img2.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/bank-fin-icon3.png";
import bankicon2 from "../img/bank-fin-icon2.png";
import icon9 from "../img/rel-icon9.png";
import bankicon5 from "../img/bank-fin-icon5.png";
import bankicon6 from "../img/bank-fin-icon6.png";
function It() {
  const  env = useContext(EnvironmentContext);
  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={itimg} alt="" />
            </div>
            <div className="col-xl-6 col-md-12 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>
                <span className="health_small pb-5">e</span>-SIGNATURE FOR <br />
                <span>IT OPERATIONS</span>
              </h3>
              <div className="pt-5">
                <button
                  className="btn-rd"
                  type="button"
                  onClick={() => window.location.href = `${env}/account/register`}
                >
                  Start Free Trial
                  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="top-prt pt-2">
        <div className="row health_adding p" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
          <div>
            <p>
              In today's economic environment where there is an ever-growing dependency on creating a robust digital experience, an organization's IT Operations team is more vital than ever before. As such, department heads cannot afford to be slowed down by inefficient administrative processes that consume valuable time better spent on vital projects. One area these leaders are looking to improve upon is document management and eSignature solutions.
            </p>
          </div>
          <div className="bank_fin_btn_rd">
            <a href={`${env}/account/register`} className="btn-rd">
              Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>
                how signulu can benefit <br />
                <span>it operations</span>
              </h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={itimg1} alt="" />
            </div>
          </div>
          <div className="real_est_sub_container3_2">
            <div className="manfact_sub_container3_2">
              <div className="manfact_sub_container3_2_p">
                <div className="manfact_sub_container3_2_1" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_2_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Digital Asset Tracking</span><br />
                      Go digital with your asset tracking process for everything from having employees sign disclosures to collecting necessary data with Signulu's integrated cloud-based platform. Then, using APIs, import data into relevant asset management systems.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Policy Management</span><br />
                      Get your employees to electronically review, acknowledge, and approve IT policies (no matter where they are in the world) with just a few clicks of a mouse and within minutes rather than hours or days.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Project Coordination and Authorization</span><br />
                      Improve cross-team communications and reduce errors by getting proper authorizations and sign-offs before making any changes to production requirements or environments.
                    </li>
                  </ul>
                </div>
                <div className="manfact_sub_container3_3" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_3_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>System Change Requests</span><br />
                      From bug fixes to new feature controls, embed authorized sign offs into your IT project management systems.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>API Integration</span><br />
                      Integrate your existing applications and websites with our open APIs so you can leverage the power of Signulu without interrupting your current workflows.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="manfact_btn_knowmore">
                <button
                  className="btn-rd"
                  type="button"
                  onClick={() => window.location.pathname = '/contact'}
                >
                  Know More
                  <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            Summary of Signulu Benefits
          </h2>
          <div className="row add_padding justify-content-center align-items-center">
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon2} alt="" />
                </div>
                <div className="right">
                  <p>Send Multiple Documents to be Signed Electronically</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon3} alt="" />
                </div>
                <div className="right">
                  <p>Keep Workflow Uninterrupted from Anywhere</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={bankicon2} alt="" />
                </div>
                <div className="right">
                  <p>Generate Similar Forms and Host Them in Seconds</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon9} alt="" />
                </div>
                <div className="right">
                  <p>Seamlessly Collaborate on Documents</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={bankicon5} alt="" />
                </div>
                <div className="right">
                  <p>Foster Staff Productivity</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={bankicon6} alt="" />
                </div>
                <div className="right">
                  <p>Minimize Risk of Data Loss</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
            <p>
              Signulu helps you to fully digitize and automate your IT operations' processes and workflows across different domains. Whether it is IT purchasing, chasing sign-offs on projects, or asset tracking - Signulu can remove process inefficiencies and improve coordination. Let Signulu handle your signing and document automation needs, while you focus on completing projects that deliver real business value.
            </p>
            <p>
              Try Signulu's{' '}
              <a href={`${env}/account/register`}>free 14-day trial</a> to see how easy it is to get started! Or,{' '}
              <a href="/contact">contact us</a> today to discuss how Signulu can meet your business needs.
            </p>
            <a href={`${env}/account/register`} className="btn-rd">
              Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
export default It;