import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';

import Header from '../Components/Header';
import Footer from '../Components/Footer';
import tick from "../img/tick.png";
//import ticklite from "../img/tick-lite-24.png"
import img1 from "../img/manfact_banner_img.png";
import img2 from "../img/manfact_solution_img.png";
import frameimg from "../img/Frame.png";
import icon4 from "../img/icon4.png";
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";

function Manufacturing() {
  const  env = useContext(EnvironmentContext);

  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img src={img1} alt="" />
            </div>
            <div className="manfact_sub_container2 pb-4" style={{ width: "49%" }}>
              <div className="col-xl-6 col-md-6 col-sm-12 text-part p-4" data-aos="fade-up" data-aos-duration="2500">
                <h3><span className="health_small">e</span>SIGNATURE FOR <span>MANUFACTURING</span></h3>
                <div className="manfact_btn_try_sign_free" style={{ marginTop: '20px' }}>
                  <button className="btn-rd" style={{ width: '252px' }} type="button" onClick={() => window.location.href = `${env}/account/register`}>
                    Start Free Trial
                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="manfact_section2" data-aos="fade-up" data-aos-duration="2500">
        <div className="manfact_container2">
          <div className="manfact_sub_container2_1">
            <div className="manfact_text_container2_1">
              <p>Did you know that, according to the Electronic Signature &amp; Records Association, businesses experience an 83% improvement in the time it takes to gain approvals and an 86% savings in document costs just by using an eSignature solution? Last I checked, anything that can save you time and money is an old age recipe for success.</p>
            </div>
            <div className="manfact_text_container2_2 pb-4">
              <p>Even before a manufacturer can begin to fulfill a customer’s order, there is a lot of time and energy spent just making sure that the appropriate specs, contracts and SOW’s are all aligned and agreed to by both parties. And, as you probably know, that can often be a longer process than actually fulfilling the manufacturing order! That being the case, it is not a surprise that eSignatures came in as a viable solution to assist in expediting this sales process.</p>
            </div>
            <div className="manft_btn_signup">
              <button className="btn-rd" type="button" onClick={() => window.location.href = `${env}/account/register`}>
                Start Free Trial
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center pb-4">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3 style={{ fontSize: '35px' }}>Why the Manufacturing Industry is <span>Benefiting</span> from the eSignature Revolution</h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="2500">
              <img style={{ width: "100%" }} src={img2} alt="" />
            </div>
          </div>
          <div className="real_est_sub_container3_2">
            <div className="manfact_sub_container3_2">
              <div className="manfact_sub_container3_2_p">
                <div className="manfact_sub_container3_2_1" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_2_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Ease of getting approvals:</span><br />
                      Be it getting approvals on contracts, change orders, or invoices, eSignature solutions from Singulu make it quick and straightforward to make them happen in no time at all. Simply sign in, upload your documents, hit send and wait. Good news is you will wait 80% less than you would sending someone a hard contract to wet sign and send back.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Reduces errors:</span><br />
                      eSignatures also help reduce errors in the recording of contracts while re-scanning physical agreements. eSign technology also ensures all the terms and conditions are recorded in a digital format at the time of creation itself, further minimizing the presence of errors.
                    </li>
                  </ul>
                </div>
                <div className="manfact_sub_container3_3" data-aos="fade-up" data-aos-duration="2500">
                  <ul className="manfact_container3_3_ul" type="none">
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Track the progress:</span><br />
                      With eSign solutions, you always know who has seen your documents and when. Furthermore, there is no way to alter any document, which means no one can come back later and question what was agreed upon in a document.
                    </li>
                    <li>
                      <i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>
                      <span style={{ marginLeft: '6px' }}>Better customer experience:</span><br />
                      Nothing says your brand is a professional and ahead of the times like giving clients the ability to sign agreements digitally. Customers can sign at any time, from anywhere, making it super convenient and easy to do business with your brand. This is the sort of convenience that makes customers remember you the next time they need to sign agreements.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="manfact_btn_knowmore">
              <button
                className="btn-rd"
                type="button"
                onClick={() => window.location.pathname = '/contact'}
              >
                Know More
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>

          </div>
        </div>
      </section>
      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <p className="text-center" data-aos="fade-up" data-aos-duration="1500" style={{ paddingTop: '30px' }}>
            "Paper forms can be a big hassle for people. Thus, Signulu helps us to manage documents for the Manufacturing Industry safely and securely."
          </p>
          <div className="row add_padding justify-content-center align-items-center">
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon3} alt="" />
                </div>
                <div className="right">
                  <p>Manage Documents On-the-go</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon2} alt="" />
                </div>
                <div className="right">
                  <p>Get Contracts Signed Faster</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon1} alt="" />
                </div>
                <div className="right">
                  <p>Collect Multiple eSignature in Minutes</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500">
              <div className="about_partner_box d-flex">
                <div className="left">
                  <img src={icon4} alt="" />
                </div>
                <div className="right">
                  <p>Process Purchase Orders Faster by Generating Reusable Templates</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
            <p >
              The digital world is the way forward in every domain and space today, why should the manufacturing industry be any different? It's time that this industry, too, adopts the digital revolution. Contact us to learn more about Signulu.            </p>
            <a href={`${env}/account/register`} className="btn-rd">
              Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>
      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }} id="rememberme" type="checkbox" checked={true} />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frameimg} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Manufacturing;