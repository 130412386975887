// src/components/Footer.js

import React from 'react';
import logo from '../img/new-signulu-logo.png';
import facebook from '../img/facebook.png';
import twitter from '../img/twitter.png';
import linkedin from '../img/Linkedin.png';
import youtube from '../img/youtube.png';
import instagram from '../img/insta.png';

function Footer() {
    return (
        <footer>
            <div className="container container-lg container-xl container-xxl">
                <div className="row pb-5">
                    <div className="col-lg-5 col-md-4 col-sm-12 footer_logo">
                        <img src={logo} className="footer-logo" alt="Logo" />
                        <p className="footer-text">An ingenious electronic signature platform that provides next level reliability, consistency, scalability and performance.</p>
                    </div>
                    <div className="col-lg-5 col-md-4 col-sm-12 ftr-menu">
                        <ul>
                            <li><a href="/about">About</a></li>
                            <li><a href="/pricing">Pricing</a></li>
                            <li><a href="./Terms of Services">Term Of Service</a></li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="./Privacy Policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 social-sec">
                        <h3>Follow us</h3>
                        <ul>
                            <li><a href="https://www.facebook.com/signulu/">
                                <img src={facebook} alt="Facebook" />
                            </a></li>
                            <li><a href="https://twitter.com/signulu">
                                <img src={twitter} alt="Twitter" />
                            </a></li>
                            <li><a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F14636333%2Fadmin%2F">
                                <img src={linkedin} alt="LinkedIn" />
                            </a></li>
                            <li><a href="https://www.youtube.com/channel/UCpYSuQ3xctOH7thHTHMmqQA?view_as=subscriber">
                                <img src={youtube} alt="YouTube" />
                            </a></li>
                            <li><a href="https://www.instagram.com/signulu/">
                                <img src={instagram} alt="Instagram" />
                            </a></li>
                        </ul>
                    </div>
                </div>
                <p className="text-center pt-5 copyrt">Copyright &copy; {new Date().getFullYear()} Signulu. All Rights Reserved</p>
            </div>
        </footer>
    );
}

export default Footer;
