import React from 'react';

const PricingCard = ({
  title,
  description,
  price,
  price1,
  duration,
  toggleSwitch,
  isYearly,
  handleToggleChange,
  features,
  link,
  backgroundClass,
  contactInfo,
  currencySymbol
}) => {
  const formatPrice = (price) => {
    const numberFormat = new Intl.NumberFormat();
    return `${currencySymbol} ${numberFormat.format(price)}`;
  };

  return (
    <div className={`col-xl-3 col-md-6 col-sm-12 padding ${backgroundClass}`}>
      <div className="card_box">
        <div className="top-cards">
          <h5>{title}</h5>
          <p>{description}</p>

          {toggleSwitch && (
            <div className="card_on">
              <span
                className="plan-duration"
                id="month"
                style={{ fontWeight: isYearly ? 'normal' : '800' }}
              >
                Monthly
              </span>
              <span>
                <input
                  type="checkbox"
                  id={`switch-${title}`}
                  checked={isYearly}
                  onChange={handleToggleChange}
                />
                <label htmlFor={`switch-${title}`}>Toggle</label>
              </span>
              <span
                className="plan-duration"
                id="year"
                style={{ fontWeight: isYearly ? '800' : 'normal' }}
              >
                Yearly
              </span>
            </div>
          )}

          {title === "Signulu Lite" || title === "Enterprise" ? (
            <div className="top-c-child">
              <h6>{price1}</h6>
            </div>
          ) : (
            <div className="card_big_amount">
              <h6>{isYearly ? formatPrice(price * 12) : formatPrice(price)}</h6>
            </div>
          )}

          {duration && (
            <span className="per_month">
              {isYearly ? "per user per annum" : "per user per month"}
            </span>
          )}

          {title === "Enterprise" ? (
            <div className="card_btns">
              <a className='cls-contactus' href={link}>Contact us</a>
            </div>
          ) : (
            <div className="card_btns">
              <a href={link}>Get started</a>
            </div>
          )}
        </div>

        <div className="card_list">
          <ul>
            {features.map((feature, index) =>
              typeof feature === 'string' ? (
                <h6 key={index}>{feature}</h6>
              ) : (
                <li key={index}>
                  <span>{feature.name}</span>
                  <span>{feature.value}</span>
                </li>
              )
            )}
          </ul>
        </div>

        {contactInfo && (
          <div>
            <div className="card_number">
              <h4>Call <span>1-877-744-6858</span></h4>
              <h4><span>+91 9176 446858</span></h4>
            </div>

            <div className="card_or">
              <h6>or</h6>
            </div>

            <div className="card_para">
              <h6>Get in Touch</h6>
              <p>Simple, Fair and affordable digital signature services for all</p>
              <p>Subscribe to the plan that meets your business needs and budget with competitive pricing options.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCard;
