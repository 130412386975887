import React from "react";
import Header from "./Header";
import Footer from "./Footer"


function PrivacyPolicy() {

    return (
        <div className="terms-of-services">
            <Header />
            <main className="container container-lg container-xl container-xxl pricing_box">
                <section>
                    <h4>Privacy Policy</h4>
                    <p>This is the first version of Signulu.com Privacy Policy and is effective as of March 2020. Signulu.com (“Signulu.com”, “we” or “us”) is committed to protecting your privacy. This Privacy Policy explains our practices regarding the collection, use, disclosure, and protection of information that is collected through our web based application and/or our mobile application and/or our website (collectively, our “Service”), as well as your choices regarding the collection and use of information.</p>
                    <p>This Privacy Policy describes how and when Signulu.com collects, uses and shares your information when you use our Services. When using any of our Services, you consent to the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy. Irrespective of which country you reside in or supply information from, you authorize Signulu.com to use your information in the United States and any other country where Signulu.com operates.</p>
                    <p>If you have any questions or comments about this Privacy Policy, please contact us at support@signulu.com.</p>
                </section>

                <section>
                    <h4>Here’s some of the information we collect:</h4>
                    <ul>
                        <li><strong>Information Collected Upon Registration:</strong> When you create or reconfigure an account with Signulu.com, you provide some personal information, including, but not limited to social media information such as but not limited to your Twitter (username, password, email address) and Facebook account information (username, password). Further, residential and/or business information including address, state, zip code, etc. may be collected. Some of this information, for example, your name and username, may be listed publicly on our Services, including on your profile page and in search results. Some Services, such as search, public user profiles and viewing lists, may not require registration. You are also agreeing that you are of the legal age in your country to provide such data and be of the legal age to work and be employed as a model or artist.</li>
                        <li><strong>Account Information:</strong> This includes at least your user name, and e-mail address;</li>
                        <li><strong>Network Performance & Usage Information:</strong> This tells us how you use our network, our products and our services, and how well our equipment and network is performing;</li>
                        <li><strong>Web Browsing & Wireless Application Information:</strong> This tells us about the websites you visit and the mobile applications you use whilst logged in to our network;</li>
                        <li><strong>Location Information:</strong> This tells us where your wireless device is located, as well as your zip code and street address;</li>
                        <li><strong>Geolocation:</strong> We may collect your location through GPS, Wi-Fi, or wireless network triangulation in order to obtain your location for the purposes of providing our Service. We may use geolocation data to build a history of occurrences of observed events that are the intent of Signulu.com, in order to bring the most relevant information to you.</li>
                        <li> <strong>Cookies:</strong> Like many websites, we use cookies and similar technologies to collect additional website usage data and to improve our Services, but we do not require cookies for many parts of our Services such as searching and looking at public user profiles or lists. A cookie is a small data file that is transferred to your computer's hard disk. Signulu.com may use both session cookies and persistent cookies to better understand how you interact with our Services, to monitor aggregate usage by our users and web traffic routing on our Services, and to customize and improve our Services. Most Internet browsers automatically accept cookies. You can instruct your browser, by changing its settings, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. However, some Services may not function properly if you disable cookies.</li>
                        <li><strong>Log Data:</strong> Our servers may automatically record information ("Log Data") created by your use of the Services. Log Data may include information such as your IP address, browser type, operating system, the referring web page, pages visited, location, your mobile carrier, device and application IDs, search terms, and cookie information. We may receive Log Data when you interact with our Services, for example, when you visit our website(s) or use our apps or otherwise sign into our Services, interact with our email notifications, use your Signulu.com account to authenticate to a third-party website or application, or visit a third-party website that includes a Signulu.com button or widget. Signulu.com uses Log Data to provide our Services and to measure, customize, and improve them. If not already done earlier, we will either delete Log Data or remove any common account identifiers, such as your username, full IP address, or email address, after 18 months.</li>
                        <li><strong>Third-Parties:</strong> Signulu.com may use a variety of third-party services to help provide our Services, such as hosting our blog(s), and to help us understand the use of our Services, such as Google Analytics. These third-party service providers may collect information sent by your browser as part of a web page request, such as cookies or your IP address. Third-party ad partners may share information with us, like a browser cookie ID or cryptographic hash of a common account identifier (such as an email address), to help us measure ad quality and tailor ads. For example, this allows us to display ads about things you may have already shown interest in. If you prefer, you can turn off tailored ads in your privacy settings so that your account is not matched to information shared by ad partners for tailoring ads.</li>
                    </ul>
                </section>

                <section>
                    <h4>Here are the ways we collect your information:</h4>
                    <ul>
                        <li>We get information from you when use our Service.</li>
                        <li>We collect it from how you use our Service.</li>
                    </ul>
                </section>
                <section>
                    <h4>Here are some of the ways we use your information:</h4>
                    <ul>
                        <li>Provide services and improve your customer experience;</li>
                        <li>Address network integrity and security issues;</li>
                        <li>Do research and analysis to maintain, protect, develop and improve our network;</li>
                        <li>Let you know about service updates, offers and promotions;</li>
                        <li>Deliver relevant advertising;</li>
                        <li>Create external marketing & analytics reports;</li>
                        <li>Assist in the prevention and investigation violations of our Terms of Use or Acceptable Use Policies.</li>
                    </ul>
                </section>
                <section>
                    <h4>Some examples of who we share your Personal Information with:</h4>
                    <ul>
                        <li>With other companies that perform services on our behalf only as needed for them to perform those services. We require them to protect your information consistent with our Policy.</li>
                        <li>With other companies and entities, to :
                            <ul>
                                <li>Comply with court orders and other legal process;</li>
                                <li>Assist with identity verification, and preventing fraud and identity theft;</li>
                                <li>Enforce our agreements and property rights; and</li>
                                <li>Obtain payment for products and services including the transfer or sale of delinquent accounts to third parties for collection.</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                <section>
                    <h4>Details on Personal and Anonymous & Aggregate Information</h4>
                    <ul>
                        <li>What is Personal Information? Information that identifies or reasonably can be used to identify you.</li>
                        <li>What is Anonymous? This is information that does not identify you and cannot reasonably be used to identify you specifically.</li>
                        <li>What is Aggregate? We take a large number of individual’s data and combine it into anonymous groups or categories.</li>
                        <li>How we use this information? We use and share this information in many ways including research, analysis, marketing and relevant advertising.</li>
                    </ul>
                </section>
                <section>
                    <h4>Our privacy commitments</h4>
                    <ul>
                        <li>We do not sell your Personal Information to anyone for any purpose.</li>
                        <li>We keep your Personal Information in our records while you are a user, or until it is no longer needed for business, tax, or legal purposes.</li>
                        <li>We will keep your information safe using encryption or other appropriate security controls.</li>
                    </ul>
                </section>
                <section>
                    <h4>Modifying Your Personal Information</h4>
                    <ul>
                        <li>If you are a registered user of our Services, we may provide you with tools and account settings to access or modify the personal information you provided to us and associated with your account.</li>
                        <li>You may also permanently delete your account by deleting the application from your mobile device or affirmatively deleting your user account via our website.</li>
                    </ul>
                </section>
                <section>
                    <h4>Our Online Privacy Policy for Children</h4>
                    <ul>
                        <li>We do not knowingly collect personally identifying information from anyone under the age of 13, unless authorized or with the knowledge of a parent or guardian.</li>
                    </ul>
                </section>
                <section>
                    <h4>Your Choices & Controls</h4>
                    <ul>
                        <li>You may have choices about which types of advertising you get from us</li>
                        <li>You may control whether your anonymous information is used in our External Marketing & Analytics Reports.</li>
                        <li>You may choose whether to receive marketing calls, e-mails, or text messages from us.</li>
                        <li>You may have a choice about how we use your Customer Proprietary Network Information.</li>
                        <p>All references regarding opting-in or obtaining consent are only relevant to UK and EU consumers and clients under the General Data Protection Regulation ("GDPR").</p>
                        <p><strong>Data Controllers and Contracting Parties:</strong> We clarify that Signulu.com is acting as the data controller and contracting party for the data of some of our Members and Visitors. Signulu.com will be the data controller and contracting party for those who live outside of the “Designated Countries” and will remain the data controller for those that live in the Designated Countries. We use the term “Designated Countries” to refer to countries in the European Union (EU), European Economic Area (EEA), and Switzerland.</p>
                    </ul>
                </section>
                <section>
                    <h4>European Union Members - International Transfer of Data</h4>
                    <p>The Photobiz platform service is international in scope. By using the Services, you consent to your private information being sent and processed in other jurisdictions outside the European Union and European Economic Area, including the United States where there may be less stringent data protection laws.</p>
                </section>
                <section>
                    <h4>For EU users only - Transferring your information outside the European Economic Area.</h4>
                    <p>As part of the services offered to you through this website, the information which you provide to us may be transferred to countries outside the European Economic Area (“EEA”). By way of example, this may happen if any of our servers are from time to time located in a country outside of the EEA. These countries may not have similar data protection laws to the EEA. In such circumstances, we will enter into model contractual clauses as adopted by the European Commission, or rely on alternative legal bases such as the Privacy Shield, where applicable, or binding corporate rules where our affiliates, consultants or service providers have adopted such internal policies approved by European data protection authorities. If you use our services while you are outside the EEA, your information may be transferred outside the EEA in order to provide you with those services.</p>
                </section>
                <section>
                    <h4>Legal Basis For The Processing of Personal Information from EEA Residents</h4>
                    <p>If you reside within the European Economic Area (EEA), our processing of your personal information will be legitimized as follows: Whenever we require your consent for the processing of your personal information such processing will be justified pursuant to Article 6(1) lit. (a) of the General Data Protection Regulation (EU) 2016/679 (“GDPR”). If the processing of your personal information is necessary for the performance of a contract between you and us or for taking any pre-contractual steps upon your request, such processing will be based on GDPR Article 6(1) lit. (b). Where the processing is necessary for us to comply with a legal obligation, we will process your information on basis of GDPR Article 6(1) lit. (c), and where the processing is necessary for the purposes of our legitimate interests, such processing will be made in accordance with GDPR Article 6(1) lit. (f).</p>
                </section>
                <section>
                    <h4>Cross-border Transfer</h4>
                    <p>We may share personal information with our affiliates' employees, consultants and third party service providers outside your country but only for purposes of performing the Services for which you provided your personal information, even to countries that might not offer a level of protection for your personal information that is equivalent to the one offered in your country of residence. We will obtain your express consent, however, before using your personal information for any purposes other than performing the Services for which you provided the personal information.</p>
                </section>
                <section>
                    <h4>Choice/Opt-out</h4>
                    <p>You will be required to ‘opt-in’ to having your personal information used for any purpose not directly related to the Site upon registration to the Site. If you no longer wish to receive additional information or promotional materials from Signulu.com, you may opt-out of receiving these communications by sending an email with the word ‘unsubscribe’ in the subject line to the following email address <a href="mailto:support@signulu.com">support@signulu.com</a> or using the opt-out mechanism described in the relevant email.</p>
                </section>
                <section>
                    <h4>Notification of Changes</h4>
                    <p>This policy may change from time to time and we will post the amended terms on the Signulu.com Site and notify you by email of major changes. Amended terms will take effect immediately for new users, and 30 days after they are posted for existing users. You should review the Privacy Policy periodically to remain informed of any changes. You agree to accept posting of a revised Privacy Policy as actual notice to you.</p>
                    <p>Your continued use of the Site after any changes to this Policy have been posted (or, if applicable, notified) signifies your acceptance of those changes. If you do not agree to the revised Privacy Statement, please refrain from using our products or services and contact us by sending an email to <a href="mailto:support@signulu.com">support@signulu.com</a>.</p>
                    <p>You can also request that we close your Signulu.com account, or request that we no longer use your information to provide you Services, by emailing Customer Service at <a href="mailto:support@signulu.com">support@signulu.com</a>. After we close your account, we may retain some information if we believe it may be necessary to prevent fraud, assist with investigations, resolve disputes, analyze or troubleshoot programs, if required by law, enforce our Terms of Use and take actions otherwise permitted by law.</p>
                </section>
                <section>
                    <h4>Questions and Feedback</h4>
                    <p>Signulu.com is strongly committed to our relationship with you and want to be sure you understand the steps we have taken to protect your personal information. If you have questions or feedback regarding our privacy policy, you may direct them to <a href="mailto:support@signulu.com">support@signulu.com</a> We will make very effort to answer your questions. If you are still not satisfied with our response, you have the right to make a formal complaint to the relevant data protection authority.</p>
                    <p>Signulu.com encourages you to review your information regularly to ensure that it is correct and complete. As a registered user, you can review and change your personal information by contacting us at the e-mail address, postal address or telephone number listed on the Signulu.com Site. You also have the right to access, rectification or erasure of the personal information held on our systems, the right to object or restrict the processing of your personal information, and the right to data portability. You may lodge a complaint with the competent data protection authority regarding the processing of your personal information. To protect the privacy and the security of your personal information, we may request information from you to enable us to confirm your identity and right to access such information, as well as to search for and provide you with the personal information we maintain. There are instances where applicable laws or regulatory requirements allow or require us to refuse to provide or delete some or all of the personal information that we maintain. You may contact us by sending an email to <a href="mailto:support@signulu.com">support@signulu.com</a> to exercise your rights. We will respond to your request in a reasonable timeframe, and in any event in less than 30 days. Signulu.com agrees to hold your information for a period of at least 120 days unless a business reason requires we hold it for more such as a subscription or you are a registered user.</p>
                    <p>Customers who wish to request further information about our compliance with these requirements, or have questions or concerns about our privacy practices and policies may contact us: <a href="mailto:support@signulu.com">support@signulu.com</a></p>
                </section>
            </main>
            <Footer />
        </div>
    )
}
export default PrivacyPolicy;