import React, { useEffect } from "react";
import video1 from "../img/Main Video Flow.mp4";
import v1img from "../img/V1.mp4";
import v3img from "../img/V3.mp4";
import v4img from "../img/V4.mp4"
import auditrail from "../img/Audit-trail.mp4";
import v2img from "../img/V2.mp4"
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import frame from "../img/Frame.png";
import dodlaimg from "../img/dodla.png";
import fixity from "../img/Fixity.png";
import heritage from "../img/Heritage.png";
import panzer from "../img/panzer.png";
import sankar from "../img/sankar.png";
import suits from "../img/Suits.png";
import maku from "../img/TKDMPMACU.png";
import turtle from "../img/turtlebay.png";
import verinite from "../img/verinite.png";
import verinite1 from "../img/verinite-1.png";
import suzlon from "../img/Suzlon.png";
import aws from "../img/aws.png";
import microsoft from "../img/microsoft.png";
import emudhra from "../img/emudhara.png";
import protean from "../img/protean.png";
import mailgun from "../img/mailgun.png";
import grbl from "../img/grbl.png"
import tcognition from "../img/tcognition.png"
function Body() {

  useEffect(() => {
    AOS.init({
      duration: 2500,
      once: true,
      disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      },
    });

    const handleLoad = () => {
      // Carousel item cloning
      const items = document.querySelectorAll(".carousel .carousel-item");
      items.forEach((el) => {
        const minPerSlide = 4;
        let next = el.nextElementSibling;
        for (let i = 1; i < minPerSlide; i++) {
          if (!next) {
            next = items[0];
          }
          const cloneChild = next.cloneNode(true);
          el.appendChild(cloneChild.children[0]);
          next = next.nextElementSibling;
        }
      });

      // Carousel navigation
      const prev = document.querySelector(".prev");
      const next = document.querySelector(".next");
      const carousel = document.querySelector(".carousel-container");
      const track = document.querySelector(".track");

      if (carousel && track) {
        let width = carousel.offsetWidth;
        let index = 0;
        window.addEventListener("resize", () => {
          width = carousel.offsetWidth;
        });

        next.addEventListener("click", (e) => {
          e.preventDefault();
          index += 1;
          prev.classList.add("show");
          track.style.transform = `translateX(${index * -width}px)`;
          if (track.offsetWidth - index * width < index * width) {
            next.classList.add("hide");
          }
        });

        prev.addEventListener("click", () => {
          index -= 1;
          next.classList.remove("hide");
          if (index === 0) {
            prev.classList.remove("show");
          }
          track.style.transform = `translateX(${index * -width}px)`;
        });
      }

      // Sticky body on scroll
      window.addEventListener("scroll", () => {
        document.body.classList.toggle("sticky", window.scrollY > 50);
      });

      // Nav dropdown toggle
      document.querySelectorAll(".nav-link").forEach((link) => {
        link.addEventListener("click", () => {
          const dropdown = document.querySelector(".nav_dropDown");
          if (dropdown) {
            dropdown.style.display = dropdown.style.display === "block" ? "none" : "block";
          }
        });
      });

      // Counter animation
      document.querySelectorAll(".counter").forEach((counter) => {
        let endValue = parseInt(counter.textContent, 10);
        let startValue = 0;
        const duration = 4000;
        const stepTime = Math.abs(Math.floor(duration / endValue));
        const timer = setInterval(() => {
          startValue += 1;
          counter.textContent = startValue;
          if (startValue === endValue) {
            clearInterval(timer);
          }
        }, stepTime);
      });

      // Word changing animation
      const words = ['Efficient', 'Secure', 'Trustworthy'];
      let wordIndex = 0;
      const changingWordElement = document.getElementById('changingword');
      if (changingWordElement) {
        setInterval(() => {
          changingWordElement.style.opacity = 0;
          setTimeout(() => {
            changingWordElement.textContent = words[wordIndex];
            changingWordElement.style.opacity = 1;
            wordIndex = (wordIndex + 1) % words.length;
          }, 500);
        }, 3000);
      }

      // Data words animation
      document.querySelectorAll("[data-words]").forEach((element) => {
        const words = element.getAttribute("data-words").split("|");
        element.innerHTML = words.map(word => `<span>${word}</span>`).join("");
        const spans = element.querySelectorAll("span");
        let index = 0;

        const loop = () => {
          element.style.width = `${spans[index].offsetWidth}px`;
          spans.forEach(span => span.style.display = 'none');
          spans[index].style.display = 'inline';
          setTimeout(() => {
            index = (index + 1) % words.length;
            loop();
          }, 2000);
        };
        loop();
      });
    };

    // window.addEventListener("load", handleLoad);

    // return () => {
    //   window.removeEventListener("load", handleLoad);
    // };
  }, []);


  return (
    <div>
      <div className="banner-part">
        <div className="container container-lg container-xl container-xxl">
          <h1 className="text-center" data-aos="fade-up">
            Easy and <span id="changingword" className="efcnt">Efficient</span> Way to <br /> Sign Contracts and Documents
          </h1>
          <ul className="d-flex justify-content-center flex-wrap" data-aos="fade-up">
            <li>Legally Binding</li>
            <li>Secure</li>
            <li>Trustworthy</li>
            <li>Affordable</li>
            <li>Powered by AI</li>
          </ul>
          <div className="d-flex justify-content-center frm-srch" data-aos="fade-up">
            {/* <form className="d-flex justify-content-between mt-6" action="https://appdev.signulu.com/account/register">
                <input className="form-control me-2" type="search" placeholder="Enter your email" aria-label="Search" />
                <button className="btn btn-outline-success" type="submit">Start Free Trial</button>
              </form> */}
          </div>
          <p className="text-center img-banner mainV" data-aos="fade-up">
            <video
              autoPlay
              playsInline
              loop
              muted
              style={{ borderRadius: "11px" }}
              className="main-video"
            >
              <source src={video1} type="video/mp4" />
            </video>
          </p>
        </div>
      </div>

      <section className="top-prt section-gap">
        <div className="container container-lg container-xl container-xxl professional-business">
          <div className="text-center" data-aos="fade-up">
            <h2>Electronic <span className="efcnt blue-grdnt-text">Signature</span> Software</h2>
            <h4>For Personal, Professional and Business Use</h4>
          </div>

          <div className="row d-flex align-items-center justify-content-center Use-interface two-boxx">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part use-interface-block" data-aos="fade-up" style={{ textAlign: 'start' }}>
              <p className="use-interface">Easy to Use interface</p>
              <p className="use-interface-text">
                Signulu provides a user friendly drag and drop interface for preparing and sending documents in minutes.
                With multiple signing options and with click of a button, documents can be signed and executed efficiently.
              </p>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up" style={{ textAlign: 'end' }}>
              <video
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={v1img} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="row flex-row-reverse d-flex justify-content-center multi-workflow align-items-center two-box">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <p className="use-interface">Multiple Workflow Options</p>
              <p className="use-interface-text">
                You can obtain signatures on the documents/contracts in a sequential, parallel or hybrid workflows with or without multi-factor authentication. You can also enable multiple signing options for signatories based on the document/contract type under the permissible local government regulations.
              </p>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <video
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={v3img} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="row d-flex justify-content-center multi-doc-storage align-items-center two-box">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part use-interface-block" data-aos="fade-up">
              <p className="use-interface">Multiple Document Storage Options</p>
              <p className="use-interface-text">
                Signulu provides multiple storage options to store the final executed contracts. This includes cloud storage, secure FTP servers or any third-party document management systems.
              </p>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <video
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={v4img} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="row flex-row-reverse d-flex justify-content-center multi-doc-storage align-items-center two-box">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <p className="use-interface">Audit Trails</p>
              <p className="use-interface-text">
                Audit Trails are an important component of Signulu that captures every digital footprint of the contract/document during the signing process. The document owner can track the activities performed during the process thereby lending integrity, transparency and traceability to the document signing process.
              </p>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <video
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={auditrail} type="video/mp4" />
              </video>
            </div>
          </div>

          <div className="row d-flex justify-content-center adv-verification align-items-center two-box">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part text-part-mob use-interface-block" data-aos="fade-up">
              <p className="use-interface">Advanced Identity Verification Capabilities using AI</p>
              <p className="use-interface-text">
                Signulu provides feature to enable identity verification process before signing any document. This acts as an effective fraud prevention measure and establishes the authenticity of the signer and document or contract.
                Signulu uses Computer Vision Algorithms and OCR programs to verify the signer's identity before signing the document.
              </p>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <video
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={v2img} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className="case-studies ourClients-section">
        <div className="container container-lg container-xl container-xxl">
          <h2 className="text-center">Our Clients</h2>

          <div className="parent">
            <div className="grandchild"><img src={dodlaimg} alt="" /></div>
            <div className="grandchild"><img src={fixity} alt="" /></div>
            <div className="grandchild"><img src={heritage} alt="" /></div>
            <div className="grandchild"><img src={panzer} alt="" /></div>
            <div className="grandchild"><img src={sankar} alt="" /></div>
            <div className="grandchild"><img src={suits} alt="" /></div>
            <div className="grandchild"><img src={maku} alt="" /></div>
            <div className="grandchild"><img src={turtle} alt="" /></div>
            <div className="grandchild"><img src={verinite} alt="" /></div>
            <div className="grandchild"><img src={verinite1} alt="" /></div>
            <div className="grandchild"><img src={suzlon} alt="" /></div>
            <div className="grandchild"><img src={grbl} alt="" /></div>
            <div className="grandchild"><img src={tcognition} alt="" /></div>
          </div>

          <div className="prtnership-part" data-aos="fade-up">
            <h3 className="text-center">Our Partnerships</h3>
            <div className="partner-logo d-flex justify-content-center align-items-center flex-wrap">
              <div><img src={aws} alt="" className="aws-partner" /></div>
              <div><img src={microsoft} className="microsoft-partner" alt="" /></div>
              <div><img src={emudhra} className="emudhra-partner" alt="" /></div>
              <div><img src={protean} className="emudhra-partner" alt="" /></div>
              <div><img src={mailgun} className="mailgun-partner" alt="" /></div>
            </div>
          </div>
        </div>
      </section>
      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trail</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked={true}
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Body;
