import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

export const EnvironmentContext = createContext();

const root = ReactDOM.createRoot(document.getElementById('root'));

let env = window.location.host;
let env1 =  window.location
console.log(env1,"1111111111")
if(env === "localhost:3000"){
  env = "https://appdev.signulu.com"
}
else if(env === "devsite"){
 env = "https://appdev.signulu.com"
}
else if(env === "qasite"){
  env = "https://appqa.signulu.com"
 }
 else {
   env = "https://app.signulu.com"
 }
console.log(env,"envvvvv")
root.render(
  <React.StrictMode>
    <EnvironmentContext.Provider value={env}>
      <App />
    </EnvironmentContext.Provider>
  </React.StrictMode>
);
