import React, { useContext } from "react";
import { EnvironmentContext } from '../index';

import Header from "../Components/Header";
import Footer from "../Components/Footer";

import tick from "../img/tick.png";
import estbanner from "../img/rel_est_banner_img.png";
import realestateimg from "../img/rel_est_solution_img_hou.png";
import icon12 from "../img/rel-icon12.png";
import icon13 from "../img/rel-icon13.png";
import icon2 from "../img/rel-icon2.png";
import icon9 from "../img/rel-icon9.png";
import icon8 from "../img/rel-icon8.png";
import icon4 from '../img/rel-icon4.png';
import frame from "../img/Frame.png"
function RealEstate() {
  const  env = useContext(EnvironmentContext);
  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <img src={estbanner} alt="" />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <h3>
                <span className="health_small">e</span>SIGNATURE IN the <span>REAL ESTATE</span>
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section className="real_est_section2" data-aos="fade-up">
        <div className="real_est_container2">
          <div className="real_est_sub_container2_1">
            <div className="real_est_text_container2_1">
              <p>
                Digital contracts and eSignatures have quickly become an integral and essential part of today's digital world. Large cabinets filled with various files used to be a standard for many offices, but today, they are all stored digitally on servers and cloud storage.
              </p>
            </div>
            <div className="real_est_text_container2_2">
              <p>
                With many industries taking advantage of the ever-expanding digital technology, it is time for the real estate industry to capitalize on the benefits of eSignature solutions from Signulu. The adoption of eSignatures makes a company agile and more in line with the online expectations of today’s consumers.
              </p>
            </div>
            <div className="real-est_btn_signup">
              <button className="btn-rd" type="button" onClick={() => window.location.href = `${env}/account/register`}>
                Start Free Trial
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <h3>Benefits of using <span><span className="health_small">e</span>Signature</span> Solutions</h3>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <img src={realestateimg} alt="" />
            </div>
          </div>
          <div className="real_est_sub_container3_2" data-aos="fade-up">
            <div className="real_est_sub_container3_2_p">
              <div className="real_est_sub_container3_2_1">
                <ul className="real_est_container3_2_ul" type="none">
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>Storage and retrieval of agreements, disclosures, and other legal and property listing documents become easier when stored and managed on the cloud.</li>
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>No more having to make multiple copies or scans of lengthy contracts for every round of edits. Make your changes electronically, save and re-send to all parties involved to review and sign online.</li>
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>No more waiting on and/or paying for expensive overnight and “snail mail” deliveries. Read, click, save. That’s all.</li>
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>It is quite accessible for the staff to retrieve, access, or share files anytime and from anywhere.</li>
                </ul>
              </div>
              <div className="real_est_sub_container3_3">
                <ul className="real_est_container3_3_ul" type="none">
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>Easily track the status of all documents and signers with automated email notifications and an administrative dashboard.</li>
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>All documents eSigned by Signulu can be legally and securely verified under the scrutiny of any audit.</li>
                  <li><i className="ri-check-fill" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>The creation of templates makes any residential or commercial transaction simpler than ever.</li>
                  <li><i className="" style={{ verticalAlign: 'middle', fontSize: '20px', color: 'blue', marginLeft: '-25px' }}><img src={tick} alt="" /></i>Digital contracts and eSignature solutions by Signulu provide one of the most secure ways to conduct business.</li>
                </ul>
              </div>
            </div>
            <div className="real-est_btn_knowmore">
              <button className="btn-rd" type="button" onClick={() => window.location.pathname = '/contact'}>
                Know More
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <p className="text-center" data-aos="fade-up" style={{ paddingTop: '30px' }}>
            Learn How Signulu Streamlines Document Workflow for Real Estate Professionals
          </p>
          <div className="row add_padding justify-content-center align-items-center">
            {[
              { src: icon12, text: 'Invite Clients to Sign a Legal Form in Seconds' },
              { src: icon13, text: 'Get Documents eSigned even On-the-go' },
              { src: icon2, text: 'Keep Confidential Data Protected' },
              { src: icon9, text: 'Seamlessly Collaborate on Documents' },
              { src: icon8, text: 'Make your Documents Look Professional' },
              { src: icon4, text: 'Achieve overhead savings on costs of paper, toner, hardware and postage.' },
            ].map((item, index) => (
              <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" key={index}>
                <div className="about_partner_box d-flex">
                  <div className="left">
                    <img src={item.src} alt="" />
                  </div>
                  <div className="right">
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up">
            <p>
              Signulu helps you to fully digitize and automate your IT operations' processes and workflows across different domains. Whether it is IT purchasing, chasing sign-offs on projects, or asset tracking - Signulu can remove process inefficiencies and improve coordination. Let Signulu handle your signing and document automation needs, while you focus on completing projects that deliver real business value.
            </p>
            <p>
              Try Signulu's <a href={`${env}/account/register`}>free 14 day trial</a> To See How Easy It Is To Get Started! Or, <a href="/contact">contact us</a> today to discuss how Signulu can meet your business needs.
            </p>
            <a href={`${env}/account/register`} className="btn-rd">
              Start Free Trial
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trail</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked={true}
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default RealEstate;