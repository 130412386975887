import React from "react";
import { Routes, Route } from 'react-router-dom';
import About from "./About";
import Pricing from "./Pricing";
import Contacts from "./Contacts";
import It from "./It";
import Manufacturing from "./Manufacturing";
import BankingandFinancial from "./Banking and Financial";
import Professional from "./Professional";
import RealEstate from "./RealEstate";
import HealthCare from "./Health Care";
import Law from "./Law";

function AllPages() {
  return (
    <div>
      <Routes>
        <Route path="about" element={<About />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="contact" element={<Contacts />} />
        <Route path="it" element={<It />} />
        <Route path="manufacturing" element={<Manufacturing />} />
        <Route path="banking-financial-services" element={<BankingandFinancial />} />
        <Route path="professional-services" element={<Professional />} />
        <Route path="real-estate" element={<RealEstate />} />
        <Route path="healthcare" element={<HealthCare />} />
        <Route path="law" element={<Law />} />
      </Routes>
    </div>
  );
}

export default AllPages;
