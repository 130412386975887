import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import frame from "../img/Frame.png";
import icon1 from '../img/health-icon1.png';
import icon2 from '../img/health-icon2.png';
import icon3 from '../img/health-icon3.png';
import icon4 from '../img/health-icon4.png';
import healthimg from "../img/health-img1.png";
import healthbanner from "../img/health-banner.png";

function HealthCare() {
  const  env = useContext(EnvironmentContext);

  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt health_top_art">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <h3>
                <span className="health_small">e</span>signatures are the <span>future</span> of healthcare
              </h3>
              <h4>for a paperless and secure transaction.</h4>
              <p>
                In the healthcare industry, nothing is more important than ensuring the well-being of all patients while reducing overhead costs. One of the areas many hospitals have identified as standing in the way of better service and cost management is the large amount of paperwork that comes with each new patient.
              </p>
              <button
                className="btn-rd"
                type="button"
                onClick={() => window.location.href = `${env}/account/register`}
              >
                Start Free Trial
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </button>
              <button
                className="btn-rd"
                type="button"
                onClick={() => window.location.pathname = '/contact'}
              >
                Know More
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="black" />
                </svg>
              </button>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <img src={healthbanner} alt="Healthcare Banner" />
            </div>
          </div>
        </div>
      </section>

      <section className="top-prt health_sec">
        <div className="container container-lg container-xl container-xxl">
          <div className="pt-4 text-center" data-aos="fade-up">
            <h2>
              <span className="health_small">e</span>SIGNATURE SOLUTIONS CAN SIMPLIFY AND IMPROVE ADMINISTRATIVE <span className="efcnt blue-grdnt-text">PROCESSES</span> IN THE HEALTHCARE INDUSTRY IN THE FOLLOWING WAYS:
            </h2>
          </div>
          <div className="row d-flex justify-content-center mt-5 two-box">
            <div className="col-xl-6 col-md-6 col-sm-12" data-aos="fade-up">
              <img src={healthimg} alt="" />
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up">
              <ul>
                <li>All records and documents may be accessed at any time and anywhere a data or internet connection is available.</li>
                <li>Speed up the check-in process, especially in emergency room situations, so patients can quickly get the attention they need.</li>
                <li>Patient visit and check-up reports become less of an administrative burden.</li>
                <li>Free up more time for your staff to be with patients or collaborating with one another to provide an optimal level of care.</li>
                <li>Improved compliance and security with encrypted eSignatures</li>
                <li>Reduction in clerical and data entry errors.</li>
              </ul>
              <a href="/contact" className="btn-rd">
                Know More
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec health_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            By now, you can see that our objective here at Signulu is to greatly reduce the seemingly endless amounts of paperwork necessary to care for each patient. By turning to an eSignature solution your organization will see the following direct benefits:
          </h2>
          <div className="row add_padding justify-content-center align-items-center">
            {[
              { src: icon1, text: 'Doctors have a longer time for direct patient care.' },
              { src: icon2, text: 'Patients can sign documents before appointments.' },
              { src: icon3, text: 'Administrators can specialize in mission-critical tasks.' },
              { src: icon4, text: 'Can decrease a considerable amount on operational costs.' },
            ].map((item, index) => (
              <div className="col-xl-3 col-md-6 padding" data-aos="fade-up" data-aos-duration="1500" key={index}>
                <div className="about_partner_box d-flex">
                  <div className="left">
                    <img src={item.src} alt="" />
                  </div>
                  <div className="right">
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row health_adding" style={{ margin: "0 auto" }} data-aos="fade-up" data-aos-duration="1500">
            <p>
              If you feel your hospital or office would benefit from a digital signature and document management solution,
              <a href="#">click here</a> to give us a call or send us an email for a demo or <a href={`${env}`}>register here</a> for a free 14 day trial!
            </p>
            <a href="/contact" className="btn-rd">
              Know More
              <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>
      </section>

      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default HealthCare
