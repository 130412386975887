import React,{useEffect} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Header from "./Header";
import Body from "./Body"
import Footer from "./Footer";


function Home() {
   useEffect(() => {
      AOS.init({ duration: 1500 }); // Initialize AOS with a duration of 1500ms
  }, []);
   return (
      <div>
         <Header />
         <Body />
         <Footer />
      </div>
   )
}
export default Home;