import React, { useEffect, useState,useContext } from "react";
import { EnvironmentContext } from '../index';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import PricingCard from './PricingCard';
import PricingTable from "./PricingTable";

import arrow from "../img/down-arrow-30.png";
import frame from "../img/Frame.png";
import ticklite from "../img/tick-lite-24.png"
import ticklite1 from "../img/tick2.png"
import ticklite2 from "../img/tick3.png";
import ticklite3 from "../img/icons8-enterpr-24.png"

function Pricing() {
  const [pricingData, setPricingData] = useState({
    userAnnualPrice: 0,
    userMonthly: 0,
    companyAnnualPrice: 0,
    companyMonthly: 0,
    CurrencySymbol: ''
  });
  const [isYearly, setIsYearly] = useState(false);
  const [isCompanyYearly, setIsCompanyYearly] = useState(false);
  const  env = useContext(EnvironmentContext);
  useEffect(() => {
    fetch('https://dev.signulu.com/index1.php/api/users/unauth/packages')
      .then((res) => res.json())
      .then((data) => {
        setPricingData({
          userAnnualPrice: data.Entity[1].AnnualPrice,
          userMonthly: data.Entity[1].MonthlyPrice,
          companyAnnualPrice: data.Entity[2].AnnualPrice,
          companyMonthly: data.Entity[2].MonthlyPrice,
          currencySymbol: data.Entity[1].CurrencySymbol
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const handleToggleChange = () => {
    debugger
    setIsYearly(!isYearly);
  };

  const handleCompanyToggleChange = () => {
    setIsCompanyYearly(!isCompanyYearly);
  };

  return (
    <div>
      <Header />
      <section className="pricing_box">
        <div className="container container-lg container-xl container-xxl">
          <div className="row">
            <div className="col-12">
              <div className="heading" data-aos="fade-up" data-aos-duration="1500">
                <h2>Signulu <span>Pricing</span></h2>
                <p>Whether you want to use Signulu as an Individual professional or for a small or medium-sized organization or large enterprise, we have a plan that fits your needs.</p>
              </div>
            </div>
          </div>
          <div className="pricing_card_box" data-aos="fade-up" data-aos-duration="1500" style={{ boxSizing: 'border-box' }}>
            <div className="cards-main-box">
              <PricingCard
                title="Signulu Lite"
                description="For a faster eSignature experience."
                // price="Free"
                price1="Free"
                features={[
                  { name: 'No. of Documents', value: '5 per month' },
                  { name: 'No. of Users', value: 'Single User' },
                  'Send Documents for eSignature',
                  { name: 'Signing Workflows', value: <img src={ticklite} alt="" /> },
                  { name: 'Essential Document Fields', value: <img src={ticklite} alt="" /> },
                  { name: 'Audit Trail', value: <img src={ticklite} alt="" /> },
                  { name: 'Notification and Reminders', value: <img src={ticklite} alt="" /> },
                  { name: '2 recipients per Envelope', value: <img src={ticklite} alt="" /> },
                  { name: '1 Document per envelope', value: <img src={ticklite} alt="" /> },
                  { name: '5 MB max size per envelope', value: <img src={ticklite} alt="" /> },
                ]}
                link={`${env}/account/register`}
                backgroundClass="background_grey"
              />
              <PricingCard
                title="Individual User"
                description="For individual professionals to get the documents signed efficiently"
                currencySymbol={pricingData.currencySymbol}
                price={isYearly ? pricingData.userAnnualPrice : pricingData.userMonthly}
                //  price={isYearly ? `₹${pricingData.userAnnualPrice * 12}` : `₹${pricingData.userMonthly}`}
                duration={isYearly ? "per user per annum" : "per user per month"}
                toggleSwitch
                isYearly={isYearly}
                handleToggleChange={handleToggleChange}
                features={[
                  { name: 'No. of Documents', value: '10 per month' },
                  { name: 'No. of Users', value: 'Single User' },
                  'All Lite user features plus:',
                  { name: 'Templates', value: <img src={ticklite1} alt="" /> },
                  { name: 'Signing Workflows', value: <img src={ticklite1} alt="" /> },
                  { name: 'Essential Document Fields', value: <img src={ticklite1} alt="" /> },
                  { name: 'Audit Trail', value: <img src={ticklite1} alt="" /> },
                  { name: 'Notification and Reminders', value: <img src={ticklite1} alt="" /> },
                  { name: 'Payments', value: <img src={ticklite1} alt="" /> },
                  { name: 'Cloud Storage', value: <img src={ticklite1} alt="" /> },
                  { name: 'Basic reports', value: <img src={ticklite1} alt="" /> },
                  { name: '5 recipients per Envelope', value: <img src={ticklite1} alt="" /> },
                  { name: '3 Documents per envelope', value: <img src={ticklite1} alt="" /> },
                  { name: '10 MB max size per envelope', value: <img src={ticklite1} alt="" /> },
                ]}
                link={`${env}/account/register`}
                backgroundClass="background_org"
              />
              <PricingCard
                title="SMB Plan"
                description="For small and medium businesses to streamline their document signing process and drive efficiency within their organizations."
                // price={isCompanyYearly ? `₹${pricingData.companyAnnualPrice * 12}` : `₹${pricingData.companyMonthly}`}
                currencySymbol={pricingData.currencySymbol}
                price={isCompanyYearly ? pricingData.companyAnnualPrice : pricingData.companyMonthly}
                duration={isCompanyYearly ? "per user per annum" : "per user per month"}
                toggleSwitch
                isYearly={isCompanyYearly}
                handleToggleChange={handleCompanyToggleChange}
                features={[
                  { name: 'No. of Documents', value: 'Unlimited' },
                  { name: 'No. of Users', value: 'Up to 5 users' },
                  'All Individual plan features plus:',
                  { name: 'Advanced Document Fields', value: <img src={ticklite2} alt="" /> },
                  { name: 'Multiple Language Sites.', value: <img src={ticklite2} alt="" /> },
                  { name: 'Mobile App', value: <img src={ticklite2} alt="" /> },
                  { name: 'Advanced reports.', value: <img src={ticklite2} alt="" /> },
                ]}
                link={`${env}/account/register`}
                backgroundClass="background_green"
              />
              <PricingCard
                title="Enterprise"
                description="For large enterprises who are looking at document processing at scale with advanced features and with integration with other enterprise applications."
                // price="Contact Us"
                contactInfo
                features={[
                  { name: 'No. of Documents', value: 'Unlimited' },
                  { name: 'No. of Users', value: 'Custom Plan' },
                  'All SMB plan features plus:',
                  { name: 'API Integrations.', value: <img src={ticklite3} alt="" /> },
                  { name: 'SSO', value: <img src={ticklite3} alt="" /> },
                  { name: 'Custom Branding', value: <img src={ticklite3} alt="" /> },
                  { name: 'Document Authoring', value: <img src={ticklite3} alt="" /> },
                ]}
                link="./contact"
                backgroundClass="background_sky"
              />
            </div>
            <div style={{ textAlign: 'center', paddingTop: '30px', paddingBottom: '30px' }}>
              <a
                href="#CompareAllFeatures"
                style={{
                  color: 'black',
                  textDecoration: 'none',
                  fontWeight: '500',
                  border: '1px solid grey',
                  padding: '10px',
                  borderRadius: '4px'
                }}
              >
                Compare All Features
                <img src={arrow} alt="" style={{ marginLeft: '10px' }} />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="priceing_all" data-aos="fade-up" data-aos-duration="1500">
              <h2>
                Simple, Fair and affordable digital <span>signature</span> services for all
              </h2>
              <p>
                Subscribe the plan that meet your business needs and budget with competitive pricing options.
              </p>
            </div>
          </div>
          <h3 style={{ textAlign: 'center', padding: '33px 0px' }} id="CompareAllFeatures">
            Compare the plans
          </h3>
          <div>
            <PricingTable />
          </div>
        </div>
      </section>
      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" name="" id="" />
                <a href="#" className="start-free-trail-with-email">Start Trail</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="remeberme"
                  type="checkbox"
                  defaultChecked={true}
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};


export default Pricing;