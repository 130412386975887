import React ,{useContext} from 'react';
import { EnvironmentContext } from '../index';

import { FaLinkedin } from 'react-icons/fa';

import Header from '../Components/Header';
import Footer from '../Components/Footer';

import banner from "../img/about-banner.png"
import icon1 from "../img/about-icon1.png";
import icon2 from "../img/about-icon2.png";
import icon3 from "../img/about-icon3.png";
import icon4 from "../img/about-icon4.png";
import icon5 from "../img/about-icon5.png";
import team1 from "../img/team1.jpg";
import team2 from "../img/team2.jpg";
import team3 from "../img/team3.jpg";
import frame from "../img/Frame.png";

function About() {
 const  env = useContext(EnvironmentContext);
 console.log(env,"111111111111111")
  return (
    <div>
      <Header />
      <section className="top-prt about_top_prt">
        <div className="container container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-6 col-md-6 col-sm-12 text-part" data-aos="fade-up" data-aos-duration="2500">
              <h3>Millennium Infotech, Inc. is the company behind <span>Signulu</span></h3>
              <p>
                An ingenious electronic signature platform that provides next level reliability, consistency, scalability, and performance. Founded by a team of professionals with a combination of process.
              </p>
              <button className="btn-rd" type="button" onClick={() => window.location.href = `${env}/account/register`}>
                Start Free Trial
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="white" />
                </svg>
              </button>
              <button className="btn-rd" type="button" onClick={() => window.location.pathname = '/contact'}>
                Know More
                <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.3928 18.0374L12.0271 16.7197L15.8605 12.9104H3.5459V10.9937H15.8605L12.0032 7.16036L13.3688 5.84265L19.4782 11.952L13.3928 18.0374Z" fill="black" />
                </svg>
              </button>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-12 " data-aos="fade-up" data-aos-duration="2500">
              <img style={{ width: "100%", height: "auto" }} src={banner} alt="banner" />
            </div>
          </div>
        </div>
      </section>

      <section className="partner-sec about_partner_sec">
        <div className="container about_part_back container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">
            Get more out of Service<br />Electronic <span>Signature</span> Service
          </h2>
          <div className="row add_padding justify-content-center" data-aos="fade-up" data-aos-duration="2000">
            {[
              { img: icon1, title: 'Recipient permission', text: 'Set various actions of each recipient in your workflow such as signing and requesting signer attachments.' },
              { img: icon2, title: 'Page Visibility', text: 'Set controls over which recipient among multiple recipients can view individual pages and sign, protecting confidential information.' },
              { img: icon3, title: 'Reminders', text: 'Set up email reminders for your signers to complete the signing process.' },
              { img: icon4, title: 'Reporting', text: 'Easy for the business account administrator to see information about the users’ usage.' },
              { img: icon5, title: 'Multiple Formats', text: 'Supports multiple formats like PDF, Microsoft Word, Excel and Image file formats for your convenience.' }
            ].map((item, index) => (
              <div className="col-xl-4 col-md-6 padding" key={index}>
                <div className="about_partner_box d-flex">
                  <div className="left">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="right">
                    <h2>{item.title}</h2>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-5 mt-5 case-studies about_cas">
        <div className="container container-lg container-xl container-xxl">
          <h2 className="text-center" data-aos="fade-up" data-aos-duration="1500">Our Leadership team</h2>
          <p data-aos="fade-up" data-aos-duration="1500">We are a varied mix of individuals committed to delivering industry-leading IT solutions.</p>
          <div className="row team_box" style={{ display: 'flex', justifyContent: 'space-around' }}>
            {[
              { name: 'Ramana Krosuri', title: 'CEO', img: team1, linkedin: 'https://www.linkedin.com/in/ramana-krosuri-12233027/' },
              { name: 'Benjamin Joseph', title: 'VP Technology Service', img: team2, linkedin: 'https://www.linkedin.com/in/benjaminjoseph/' },
              { name: 'Raghavendra AS', title: 'CTO', img: team3, linkedin: 'https://www.linkedin.com/in/raghavendra-alse-0907/' }
            ].map((member, index) => (
              <div className="col-xl-3 col-md-6 col-sm-12 padding" data-aos="fade-up" data-aos-duration={(index + 1) * 1000} key={index}>
                <div className="team_box_sub">
                  <div className="image">
                    <img src={member.img} alt={member.name} />
                  </div>
                  <div className="text">
                    <h5>{member.name}</h5>
                    <h6>{member.title}</h6>
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="trial-box" data-aos="fade-up" data-aos-duration="2500">
        <div className="container container-lg container-xl container-xxl">
          <div className="row background align-items-center justify-content-between m-0 trySignulu-inp-box">
            <div className="col-xl-6 col-md-12 text-mail">
              <h3>Get up to 5 documents signed for free, every month</h3>
              <p>Try Signulu eSignature for 14 days. No credit card required.</p>
              <div className="your-email-id-box">
                <input type="email" placeholder="Your email id" className="email-inp-field" />
                <a href="#" className="start-free-trail-with-email">Start Trial</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ width: '20px', height: '20px', backgroundColor: '#FFFFFF', border: 'blue' }}
                  id="rememberme"
                  type="checkbox"
                  defaultChecked
                />
                <p className="subscrib">Subscribe to our Newsletters</p>
              </div>
              <p className="no-card">*No credit card required</p>
            </div>
            <div className="col-xl-6 col-md-12 btn-part img-frame">
              <img src={frame} className="frame-img" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;

